<!-- 厂家供货表 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20">
      <!-- 生产产商 -->
      <FormItem>
        <span>生产厂商：</span>
        <Select placeholder="请输入" v-model="queryFrom.factory_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in factoryList" :value="item.subfactory_id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 进货仓库 -->
      <FormItem class="marginLeft60">
        <span>进货仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 采购时间 -->
      <FormItem class="marginLeft60">
        <span>采购时间：</span>
        <DatePicker type="date" @on-change="changeTime($event, 1)" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" @on-change="changeTime($event, 2)" :options="options" v-model="queryFrom.end_time" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 产品编码  -->
      <FormItem>
        <span>产品编码：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model.trim="queryFrom.product_model_code" />
      </FormItem>
      <!-- 产品名称 -->
      <FormItem class="marginLeft60">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" clearable filterable class="iviewIptWidth250" @on-change="productChange">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号 -->
      <FormItem class="marginLeft60">
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth307 marginRight40" multiple filterable clearable v-model="queryFrom.specification_id_str" :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginLeft60">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Exoprt">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
      </FormItem>
      <!-- 导出 -->
      <!-- <FormItem class="po-create-number">
        <span class="pageBtn finger btnSure marginLeft20">
          <Spin v-if="Loagin" style="display: inline-block; color: white;">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Exoprt">导出</span>
        </span>
      </FormItem> -->
    </Form>
    <div class="clearfix tabDiv" ref="tabDiv">
      <Spin fix v-if="Loading"></Spin>
      <Table @on-sort-change="sortHospital" :height="tableHeight" :showSummary="true" :summaryMethod="summaryMethod" :productList="listColumns" :productData="listData" border class="table"></Table>
    </div>
    <template v-if="listData.length != 0">
      <div class="marginTop20">
        <Page v-if="total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl page" />
        <Page v-if="total > 60" show-elevator :total="total" :current="queryFrom.page" @on-change="changePage" class="fl page" />
        <div class="fr totalFont page">共计{{ total }}条记录</div>
      </div>
    </template>
    <!-- <Spin size="large" ></Spin> -->
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'factorySupplyList',
  components: {
    Table,
  },
  data() {
    return {
      Loagin: false,
      Loaging: false,
      options: {},
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
          key: 'index',
        },
        {
          title: '生产厂商',
          key: 'factory_name',
          align: 'center',
          minWidth: 240,
        },
        {
          title: '供货品种',
          key: 'supplied_varieties',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '供货总量',
          key: 'purchase_quantity',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '供货金额',
          key: 'purchase_amount',
          sortable: 'custom',
          align: 'center',
          // width: 150,
          minWidth: 120,
          render: (h, param) => {
            let money = param.row.purchase_amount
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
        },
        {
          title: '供货不含税金额',
          key: 'no_tax_purchase_amount',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                '¥' + param.row.no_tax_purchase_amount
              ),
            ])
          },
        },
        {
          title: '采购比重',
          key: 'purchase_proportion',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            let money = ((param.row.purchase_amount / this.purchase_amount_tatol) * 100).toFixed(4)
            // console.log(Number(param.row.purchase_amount.replace(/,/gi,'')),this.purchase_amount_tatol)
            money = money ? money : 0
            return h('span', money + '%')
          },
        },
        {
          title: '销售总量',
          key: 'sale_quantity',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '销售金额',
          key: 'sale_amount',
          sortable: 'custom',
          align: 'center',
          // width: 150,
          minWidth: 120,
          render: (h, param) => {
            let money = param.row.sale_amount
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
        },
        {
          title: '销售不含税金额',
          key: 'no_tax_sale_amount',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                '¥' + param.row.no_tax_sale_amount
              ),
            ])
          },
        },
        {
          title: '利润',
          key: 'profit',
          align: 'center',
          sortable: 'custom',
          // width: 150,
          minWidth: 120,
          render: (h, param) => {
            let money = param.row.profit
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
        },
        {
          title: '利润率',
          key: 'profit_margin',
          align: 'center',
          minWidth: 130,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 150,
          maxWidth: 200,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.details(param.row.factory_id)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 厂家下拉列表
      factoryList: [],
      // 表单拉下选项
      thirdList: [],
      // 仓库下拉
      warehouseList: [],
      // 产品下拉
      productList: [],
      // 经手人列表
      userList: [],
      // 查询参数
      queryFrom: {
        warehouse_id: '',
        factory_id: '',
        product_id: '',
        begin_time: '',
        end_time: '',
        product_model_code: '',
        specification_id_str: [],
      },
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      supplied_varieties_tatol: '',
      purchase_quantity_tatol: '',
      purchase_amount_tatol: '',
      total_no_tax_sale_amount: '',
      total_no_tax_purchase_amount: '',
      Loading: false,
      tableHeight: '0',
      modelList: [],
    }
  },
  created() {},
  mounted() {
    this.getfactoryList()
    // this.getfactorySupplyList()
    this.getpermissionWareHouseList()
    this.getProductList()
    this.getpublicSpecialUser()
    this.factorySupplyTotal()
    this.queryProductCodeSelect()
  },
  methods: {
    sortHospital(data) {
      if (data.order == 'desc') {
        this.queryFrom.sort_type = '1'
        this.queryFrom.sort_str = data.key
      } else if (data.order == 'asc') {
        this.queryFrom.sort_type = '2'
        this.queryFrom.sort_str = data.key
      } else {
        this.queryFrom.sort_type = ''
        this.queryFrom.sort_str = ''
      }
      this.getfactorySupplyList()
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.specification_id_str = []
      this.queryProductCodeSelect(e)
    },
    // 规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$api.acceptanceCodeSelect, { product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 合计接口
    factorySupplyTotal() {
      let data = {
        warehouse_id: this.queryFrom.warehouse_id,
        factory_id: this.queryFrom.factory_id,
        product_id: this.queryFrom.product_id,
        begin_time: this.queryFrom.begin_time,
        end_time: this.queryFrom.end_time,
      }
      this.$http.get(this.$api.factorySupplyTotal, data, false).then(res => {
        this.supplied_varieties_tatol = res.data.supplied_varieties_tatol
        // 供货总量
        this.purchase_quantity_tatol = res.data.purchase_quantity_tatol
        // 供货金额
        this.purchase_amount_tatol = res.data.purchase_amount_tatol.toFixed(2)
        // 利润
        this.profit_total = res.data.profit_total.toFixed(2)
        // 销售金额
        this.sale_amount_tatol = res.data.sale_amount_tatol.toFixed(2)
        // 销售总量
        this.sale_quantity_tatol = res.data.sale_quantity_tatol
        // 不含税采购总金额
        this.total_no_tax_purchase_amount = res.data.total_no_tax_purchase_amount.toFixed(2)
        // 不含税销售总金额
        this.total_no_tax_sale_amount = res.data.total_no_tax_sale_amount.toFixed(2)
      })
    },
    // 导出时间
    Exoprt() {
      if (this.Loagin) {
        return
      }
      let data = {
        warehouse_id: this.queryFrom.warehouse_id,
        factory_id: this.queryFrom.factory_id,
        product_id: this.queryFrom.product_id,
        begin_time: this.queryFrom.begin_time,
        end_time: this.queryFrom.end_time,
        sort_type: this.queryFrom.sort_type,
        sort_str: this.queryFrom.sort_str,
      }
      this.Loagin = true
      this.$http.downFile(this.$api.getExportFactorySupply, data).then(res => {
        // this.Loading = false
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '厂商供货表导出.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.begin_time = e
        that.queryFrom.end_time = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.begin_time)
          },
        }
      } else {
        this.queryFrom.end_time = e
      }
    },
    // 点击查询
    query() {
      this.getfactorySupplyList()
      this.factorySupplyTotal()
    },
    // 页码改变
    changePage(e) {
      this.pages.page = e
      this.getfactorySupplyList()
    },
    // 获取厂家供货表
    getfactorySupplyList() {
      this.Loading = true
      let data = {
        page: this.pages.page, // 页
        rows: this.pages.rows, // 行
        warehouse_id: this.queryFrom.warehouse_id,
        sort_str: this.queryFrom.sort_str,
        sort_type: this.queryFrom.sort_type,
        factory_id: this.queryFrom.factory_id,
        product_id: this.queryFrom.product_id,
        begin_time: this.queryFrom.begin_time,
        end_time: this.queryFrom.end_time,
        model_name: this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : null,
        product_model_code: this.queryFrom.product_model_code,
      }
      this.$http.get(this.$api.factorySupply, data, false).then(res => {
        this.Loading = false
        this.total = res.data.total
        // 存储表格数据
        this.listData = res.data.result
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tabDiv.offsetHeight - 50 + ''
          this.$forceUpdate()
        })
      })
    },

    // 厂家下拉
    getfactoryList() {
      this.$http.get(this.$api.factoryList, false).then(res => {
        this.factoryList = res.data
      })
    },

    // 仓库下拉
    getpermissionWareHouseList() {
      let data = {
        page: 1,
        rows: 99999,
      }
      this.$http.get(this.$api.permissionWareHouseList, data, true).then(res => {
        this.warehouseList = res.data
      })
    },

    // 产品下拉
    getProductList() {
      let data = {
        search_type: 3,
      }
      this.$http.get(this.$api.receiptProductList, { supplier_id: null }, false).then(res => {
        this.productList = res.data
      })
    },
    // 经手人
    getpublicSpecialUser() {
      let data = {
        user_type: '1',
      }
      this.$http.get(this.$api.publicSpecialUser, data, true).then(res => {
        this.userList = res.data
      })
    },
    strInsert(str, length) {
      let reg = new RegExp('\\d{1,' + length + '}', 'g')
      let ma = str.match(reg)
      return ma.join(',')
    },
    // 数字转金额处理
    formatMoney(totalNum) {
      // if (typeof totalNum === 'number') {
      //   return totalNum + '.00'
      // } else {
      //   return totalNum
      // }
      let money = 0
      if (totalNum >= 0) {
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      } else {
        totalNum = Math.abs(totalNum)
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
        money = '-' + money
      }
      return money
    },
    summaryMethod({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        // key 就是每一列绑定的key
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        if (index === 1) {
          sums[key] = {
            key,
            value: '',
          }
          return
        }
        if (index === 2) {
          sums[key] = {
            key,
            value: this.supplied_varieties_tatol,
          }
          return
        }
        if (index === 3) {
          sums[key] = {
            key,
            value: this.purchase_quantity_tatol,
          }
          return
        }
        if (index === 4) {
          sums[key] = {
            key,
            // value: '¥' + this.strInsert(this.purchase_amount_tatol + '', 3) + '.00',
            value: '¥' + this.formatMoney(this.purchase_amount_tatol),
          }
          return
        }
        if (index === 5) {
          sums[key] = {
            key,
            value: '¥' + this.formatMoney(this.total_no_tax_purchase_amount),
          }
          return
        }
        if (index === 6) {
          sums[key] = {
            key,
            value: '100.00%',
          }
          return
        }
        if (index === 7) {
          sums[key] = {
            key,
            value: this.sale_quantity_tatol,
          }
          return
        }
        if (index === 8) {
          sums[key] = {
            key,
            // value: '¥' + this.strInsert(this.sale_amount_tatol + '', 3) + '.00',
            value: '¥' + this.formatMoney(this.sale_amount_tatol),
          }
          return
        }

        if (index === 9) {
          sums[key] = {
            key,
            // value: '¥' + this.strInsert(this.profit_total + '', 3) + '.00',
            value: '¥' + this.formatMoney(this.total_no_tax_sale_amount),
          }
          return
        }
        if (index === 10) {
          sums[key] = {
            key,
            // value: '¥' + this.strInsert(this.sale_amount_tatol + '', 3) + '.00',
            value: '¥' + this.formatMoney(this.profit_total),
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        // every() 方法用于检测数组所有元素是否都符合指定条件
        // 如果数组中检测到有一个元素不满足，则整个表达式返回 false ，且剩余的元素不会再进行检测。
        // 如果所有元素都满足条件，则返回 true。
        // 如果有是NaN的

        // isNaN 是数字返回false 其他返回true
        // 检查values里面是否都是数字
        // 如果全都是数字的话
        if (!values.every(value => isNaN(value))) {
          // reduce用于累加
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: v,
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
      })
      return sums
    },
    // 详情
    details(id) {
      this.$router.push({
        path: '/detailsFactorySupplyList',
        query: {
          id: id,
          product_id: this.queryFrom.product_id,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
</style>
